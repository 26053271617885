

/* For Desktop */
.grid{
  max-width: 1440px; /* max width */
  margin-left: 50px;  /* margin on the left */
  margin-right: 50px; /* margin on the right */
  display: grid;
  grid-template-columns: repeat(12,1fr); /*12 columns*/
  grid-gap: 20px; /* gutter */
}
.c1{
  grid-column: 1 / -1; 
}

.c12 {
  grid-column: 12 /span 2;
  overflow: visible;

}
.c4-9{
  grid-column: 4/span 6;
}
.c6-7{
  grid-column:6/span 2 ;
  width: 100%;
}
.c2-11{
  grid-column:2/span 10 ;
  width: 100%;
}
.all-screen{
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
}
/* For monitors */
@media (min-width: 1541px) {
  .grid {
    margin: 0 auto;
    grid-template-columns: repeat(12,1fr); /*12 columns*/
    grid-gap: 20px; /* gutter */

  }
}

/* For mobile */
/* Media query for screens with a width less than 767px */
@media (max-width: 767px) {
  .grid {
    margin-left: 20px;  /* margin on the left */
    margin-right: 20px; /* margin on the right */
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    grid-gap: 15px; /* gutter */
  }
  .c1{
    grid-column: 1 / -1; 
  }
  .c2{
    grid-column: 2 / -1; 
  }
  .c3{
    grid-column: 4 / span 1; 
    overflow: visible;
  }
.c4-end {
  grid-column-start: 4;
  grid-column-end: 5;
}
.all-screen{
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  
}
}


