.faqhero {
  padding: 50px 90px 120px 90px;
}
.faqplannerhero {
  padding: 20px 90px 80px 90px;
}
.faqhome {
  font-weight: 500;
  font-size: 14px;
}
.faqhomeactive {
  color: #FF5C00;
  font-weight: 500;
  font-size: 14px;
}
/* policies */
.registered-lists {
  font-weight: 400;
  font-size: 15px;
}
.amzaon-link {
  border-bottom: 0.8px solid #ffffff;
}
@media only screen and (max-width: 1200px) {
  .faqhero,
  .faqplannerhero {
    padding: 0px 70px 50px 70px;
  }
}
@media only screen and (max-width: 768px) {
  .faqhero,
  .faqplannerhero {
    padding: 0px 50px 70px 50px;
  }
  .faqheader {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 500px) {
  .faqhero,
  .faqplannerhero {
    padding: 0px 25px 70px 25px;
  }
  .faqheader {
    padding: 20px 0px !important;
  }
  .mainboxfaq {
    padding: 5px !important;
  }
}
