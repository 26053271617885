.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
tr,
th,
td {
  font-family: "Gordita Regular", sans-serif !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Inter Bold";
  font-weight: 800;
  src: url(./static/assets/font/interBold.ttf);
}

@font-face {
  font-family: "Gordita Ultra";
  font-weight: 800;
  src: url(./static/assets/font/Gordita\ Ultra.otf);
}
@font-face {
  font-family: "Gordita Medium";
  font-weight: 600;
  src: url(./static/assets/font/Gordita\ Medium.otf);
}
@font-face {
  font-family: "Gordita Regular";
  font-weight: 400;
  src: url(./static/assets/font/Gordita\ Regular.otf);
}
@font-face {
  font-family: "Gordita Bold";
  font-weight: 700;
  src: url(./static/assets/font/Gordita\ Bold.otf);
}
@font-face {
  font-family: "Heebo";
  src: url(./static/assets/font/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Poppins Medium";
  font-weight: 600;
  src: url(./static/assets/font/Poppins-Medium.ttf);
}
.interBold {
  font-family: "Inter Bold", sans-serif;
}
.gordita800 {
  font-family: "Gordita Ultra", sans-serif !important;
}
.gordita700 {
  font-family: "Gordita Bold", sans-serif !important;
}
.torus-button:hover {
  background-color: #3b77b6 !important;
}
.gordita600 {
  font-family: "Gordita Medium", sans-serif !important;
}
.gordita500 {
  font-family: "Gordita Regular", sans-serif !important;
  font-weight: 500;
}
.gordita400 {
  font-family: "Gordita Regular", sans-serif !important;
}

.barClass {
  width: 20px !important;
}

.swiper {
  z-index: 0 !important;
}

.chakra-checkbox__control {
  color: rgba(255, 255, 255, 0.6);
}

.chakra-checkbox__label {
  margin-top: 5px !important;
}

.ant-picker-content td {
  border: none !important;
}

.ant-picker-content th {
  border: none !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #ff5c00 !important;
}

.ant-picker-content input {
  color: white !important;
}

.ant-picker-suffix {
  color: white !important;
}

.ant-picker-input input::placeholder {
  color: white !important;
}

.shared .ant-picker-input input::placeholder {
  color: #a6a0bb !important;
  font-size: 16px;
}

.shared .ant-picker-suffix {
  color: #a6a0bb !important;
}

.shared .ant-picker-input input {
  color: #a6a0bb !important;
  font-size: 16px;
}

.ant-picker-input input {
  color: #ffffff !important;
}

#torusIframe {
  color-scheme: light;
}

.ticketTypeTabs button[aria-selected="true"] {
  background-color: #ff5c00 !important;
  color: #ffffff !important;
}

.ticketTypeTabs button {
  color: #ffffff !important;
}

.round {
  border-radius: 50% !important;
}

.hiddenLocationSwitch > span {
  background-color: #c2b8ff;
}

.hiddenLocationSwitch > span > span {
  background-color: #ffffff;
}
.hiddenLocationSwitchOn > span {
  background-color: #000000;
}

.barcharHover:hover {
  background-color: transparent !important;
}
.tickets-component-desktop {
  width: 387px;
  height: 550px;
  border-radius: 40px;
  margin-top: 15px;
  box-shadow: inset 1px 1px 0 0 #929295, inset -1px -1px 0 0 #353638;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 11.87rem;
}

.tickets-component-mobile {
  width: 100%;
  height: 550px;
  border-radius: 40px;
  margin-top: 15px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 2rem;
}

.tickets-component-header-desktop {
  width: 100%;
  padding: 0 30px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.tickets-component-header-mobile {
  width: 100%;
  padding: 0 12px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.tickets-component-title-desktop {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.tickets-component-title-mobile {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.tickets-component-date {
  color: white;
}

.tickets-component-location {
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-your-ticket-button {
  width: 85%;
  height: 44px;
  background-color: #ff5c00;
  color: white;
  border: none;
  font-weight: bold;
  margin-top: 10px;
}

.likes {
  color: #ffffff;
}

@media screen and (max-width: 1366px) {
  .tickets-component-desktop {
    width: 330px;
    height: 470px;
  }

  .get-your-ticket-button {
    height: 36px;
  }

  .tickets-component-title-desktop {
    font-size: 16px;
  }
}
