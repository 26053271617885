/* Radio buttons group */
/* .tags-container {
  display: flex;
  width: 100%;
  gap: 0.63rem;

  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
}

.tags-container
  > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #ffffff;
  color: #ffffff;
}
.tags-container .ant-radio-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 2.1875rem;
  background-color: transparent;
  color: #ffffff;
  line-height: 150%;
  letter-spacing: -0.01rem;
  font-size: 1rem;
  font-family: "Gordita Bold", sans-serif !important;
  font-weight: 700;
  padding: 0.44rem 1.25rem;
  @media only screen and (max-width: 670px) {
    width: 100% !important;
  }
}

.tags-container .ant-radio-button-wrapper::before {
  display: none;
}

.tags-container .ant-radio-button-wrapper:active {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.tags-container
  .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
  border-color: rgba(255, 255, 255, 0.5);
} */

/* Checkbox buttons group */
.tags-container.ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.63rem;

  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
}

.tags-container.ant-checkbox-group > label.ant-checkbox-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 2.1875rem;
  background-color: transparent;
  color: #ffffff;
  line-height: 150%;
  letter-spacing: -0.01rem;
  font-size: 1rem;
  font-family: "Gordita Bold", sans-serif !important;
  font-weight: 700;
  padding: 0.44rem 1.25rem;
  margin: 0;
  @media only screen and (max-width: 670px) {
    width: 100% !important;
  }
}

.tags-container.ant-checkbox-group
  > label.ant-checkbox-wrapper
  > span:first-child {
  display: none;
}

.tags-container.ant-checkbox-group
  > label.ant-checkbox-wrapper
  > span:last-child {
  padding: 0;
}

.tags-container.ant-checkbox-group > label.ant-checkbox-wrapper-checked {
  border-color: #ffffff;
}

.tags-container.ant-checkbox-group
  > label.ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-checked) {
  border-color: rgba(255, 255, 255, 0.5);
}

.tags-container.ant-checkbox-group > label.ant-checkbox-wrapper:active {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.currency-selector .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: #ffffff;
  height: 3.4375rem !important;
  border-radius: 6.25rem;
}

.currency-selector.ant-select-focused {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: #ffffff !important;
}

.currency-selector .ant-select-selector .ant-select-selection-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 3rem;
  font-weight: bold;
  color: #ffffff;
}

.currency-selector .ant-select-arrow {
  color: #ffffff !important;
  padding-right: 1rem;
}

/* .popup-currency-selector {
  background-color: transparent;
  box-shadow: 0px 0px 50px 0px rgba(97, 97, 97, 0.25);
}
.popup-currency-selector .ant-select-item-option-content {
  color: #ffffff;
} */

input[type="checkbox"] {
  accent-color: #ffffff;
}

.custom-date-picker {
  width: 112%;
  height: 56px;
  border-radius: 0.625rem;
  background-color: #000000;
  border: 1px solid rgba(255, 255, 255, 0.20);
  color: #FFFFFF;
  outline: none; /* Remove default focus outline */
  padding: 15px;
}

/* Styles for when the input is focused */
.custom-date-picker:focus {
  border-color: #FFFFFF;
}

/* Styles for when hovering over the input */
.custom-date-picker:hover {
  border-color: #FFFFFF !important;
}

/* Placeholder text color */
.custom-date-picker::placeholder {
  color: rgba(255, 255, 255, 0.40);
}
