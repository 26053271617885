.active {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.inactive {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background-color: none;
  cursor: pointer;
}
