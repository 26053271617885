.soccerhero {
  /* padding: 5px 65px 65px 65px; */
  /* background-color: gray !important; */
}
.soccercards {
  max-width: 430px;
  width: 100%;
}
.checkheadershow {
  display: none;
}
.newseventsbg {
  background-image: url(../../static/assets/images/bgleft.png) !important;
}
.promoinput {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  margin-top: 7px !important;
}
.promoinput::placeholder {
  padding-left: 10px;
}
.promoinput:focus {
  outline: none;
}
.intrestedbtn {
  max-width: 210px;
  width: 100%;
}
.intrestedbtn:focus {
  background-color: #ff5c00 !important;
  color: #ffff !important;
}
@media only screen and (min-width: 1440px) {
  .soccercardshero {
    justify-content: space-around !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1440px) {
  .soccercards {
    max-width: 370px;
    width: 100%;
  }
}
@media only screen and (max-width: 1300px) {
  .ticketshero {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    top: -13rem !important;
  }
  .preservehero {
    gap: 30px;
  }

  .soccerhero {
    padding: 5px 20px 0px 20px;
  }
  .soccercardshero {
    margin-top: -124px !important;
  }
  .teammembershero {
    padding: 0px 30px;
  }
}

@media only screen and (max-width: 768px) {
  .intrestedbtnhero {
    justify-content: center !important;
    gap: 30px;
  }
  .darkcard {
    width: 100% !important;
  }
  .darkcard1 {
    width: 100% !important;
  }
  .checkheader {
    display: none;
  }
  .checkheadershow {
    display: block;
    padding-bottom: 20px;
  }
  .regularcards {
    flex-direction: column;
    justify-content: center;
  }
  .birdcard {
    padding-top: 75px;
  }
  .teammemberschild,
  .teammemberheading {
    justify-content: center !important;
    align-items: center;
    text-align: center;
  }

  .preservehero {
    top: 2rem !important;
    justify-content: center !important;
    display: flex;
  }
  .soccercardshero {
    margin-top: 54px !important;
  }
}
@media only screen and (max-width: 500px) {
  .mlshero {
    flex-direction: column !important;
    gap: 15px !important;
  }
  .ticketshero {
    padding: 0px !important;
  }
  .preservehero {
    padding: 0px !important;
  }
  .preserveheading {
    font-size: 28px !important;
  }
  .trackcards {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px !important;
  }
  .organizedcard {
    flex-wrap: wrap !important;
    gap: 25px !important;
  }
}
