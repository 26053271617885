.back-top > div {
  background-color: #000000 !important;
}

.back-top.ant-float-btn {
  z-index: 20 !important;
}

.mobile-back-top > * {
  background-color: unset !important;
}

.mobile-back-top.ant-float-btn-circle {
  inset-inline-end: unset !important;
  inset-block-end: 65px !important;
}
