.control-header-component-title {
  order: 1;
}

.control-header-component-filters {
  order: 2;
}

.control-header-component-button {
  order: 3;
}

@media (max-width: 1024px) {
  .control-header-component-filters {
    order: 3;
  }

  .control-header-component-button {
    order: 2;
  }
}
