.team-component-header-title {
  order: 1;
}

.team-component-header-filters {
  order: 2;
}

.team-component-header-button {
  order: 3;
}

.team-component-header-container {
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .team-component-header-filters {
    order: 3;
  }

  .team-component-header-button {
    order: 2;
  }

  .team-component-header-container {
    justify-content: center;
  }
}
