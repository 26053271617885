.footer-container {
  max-width: 73.625rem;
  width: 100%;
  height: 16.1875rem;
  margin: 0 auto;
  border-radius: var(--radius-rl, 3.125rem);
  border: 1px solid var(--border-b-primary, rgba(255, 255, 255, 0.2));
  margin-bottom: 3rem;
  padding: 2.5rem 4.69rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer-row-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 1rem;
}

.footer-row-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.footer-right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.footer-divider {
  width: 100%;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-description {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  grid-column: 5/9;
  height: 50px;
}
.social-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.social-icon-container:hover {
  background-color: #ffffff0d;
}

@media screen and (max-width: 1300px) {
  .footer-container {
    height: auto;
    gap: 2rem;
    max-width: 940px;
    width: 90%;
    margin: 0 auto 3rem;
  }

  .footer-row-1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  .footer-row-2 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 2.5rem 2.69rem;
    max-width: 390px;
    width: 90%;
    margin: 0 auto;
  }
  .footer-container {
    margin-bottom: 8rem;
  }
  .footer-right-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5px;
  }

  .footer-row-2 {
    .footer-right-content {
      flex-direction: column-reverse;
      gap: 1rem !important;
    }
  }
}
