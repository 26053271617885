.event-details-wrapper {
  height: 100%;
  width: 100%;
  max-width: 1220px;
  position: relative;
  margin: 5rem auto 0rem;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.event-left-components {
  grid-column: span 6;
  min-width: 0;
}

.event-separator {
  grid-column: span 1;
}

.event-right-components {
  grid-column: span 5;
  align-self: start;
  top: 8rem;
}

@media only screen and (max-width: 991px) {
  .event-details-wrapper {
    /* margin: 40px; */
    margin: 1.25rem auto 0;
    padding: 0 20px;
    grid-template-columns: repeat(1, 1fr);
  }
  .event-left-components {
    position: relative;
    grid-column: span 1;
    width: 100%;
  }
  .event-separator {
    grid-column: span 0 !important;
  }

  .event-right-components {
    grid-column: span 1;
  }
}

.div-separator {
  height: 1px;
  width: 100%;
  margin: 1.7rem 0;
  background-color: #ffffff1a;
}

.faqs-section {
  max-width: 980px;
  width: 100%;
}

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 0.63rem;
  overflow: hidden;
  border-radius: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-radius: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.site-collapse-custom-collapse
  .site-collapse-custom-panel
  > .ant-collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.site-collapse-custom-panel span {
  font-family: "Gordita Medium", sans-serif !important;
  font-weight: 600;
}

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse
  .site-collapse-custom-panel
  > .ant-collapse-header {
  padding: 10px 5px 10px 10px;
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 500;
}

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse
  .site-collapse-custom-panel
  > .ant-collapse-content {
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .div-separator {
    margin: 1.25rem 0;
  }
  .site-collapse-custom-collapse .site-collapse-custom-panel,
  .site-collapse-custom-collapse
    .site-collapse-custom-panel
    > .ant-collapse-header {
    font-size: 1.1875rem;
  }

  .site-collapse-custom-collapse .site-collapse-custom-panel,
  .site-collapse-custom-collapse
    .site-collapse-custom-panel
    > .ant-collapse-content {
    font-size: 1rem;
  }
}