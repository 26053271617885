.orderhero:focus {
  font-family: "Gordita";
}
.orderhero:hover {
  color: #ff5c00 !important;
}
.sale_effect:hover {
  text-decoration: underline;
}
.arrow_icon:hover {
  background-color: #ff5c00 !important;
}
